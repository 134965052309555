<template>
  <div class="mt-5 mb-10">
    <div class="md:flex justify-between pb-3 mb-5 border-b">
      <div v-if="!editing" class="flex-init">
        <span class="text-2xl text-gray-700 font-headings">{{signalizer.name}}</span>
        <span class="ml-3 text-gray-400 text-sm">{{signalizer.serialNumber | normalizeSerialNumber}}</span>
        <span v-if="online && [999, -999].indexOf(signalizer.temperature) === -1" :class="{ 'ml-10': true,  'font-bold': true, 'text-green-500': currentStateColor=='green', 'text-red-500': currentStateColor=='red', 'text-blue-500': currentStateColor=='blue', 'text-yellow-500': currentStateColor=='yellow', 'text-purple-500': currentStateColor=='purple', 'text-gray-400': currentStateColor=='gray'}">{{signalizer.temperature}} &#8451;</span>
        <span v-else-if="online && signalizer.temperature === 999" :class="{ 'ml-5': true,  'font-bold': true, 'text-red-500': true}">Zkrat na sondě</span>
        <span v-else-if="online && signalizer.temperature === -999" :class="{ 'ml-5': true,  'font-bold': true, 'text-red-500': true}">Odpojená sonda</span>
        <span v-else :class="{ 'ml-10': true}"> </span>
        <router-link v-if="signalizer.lastActivity && canShow(signalizer.lastActivity)" :to="temperaturesPage">
          <ChartIcon class="cursor-pointer h-4 w-auto inline ml-2 mb-1 text-gray-600"/>
        </router-link>
        <span :class="{'ml-10': [999, -999].indexOf(signalizer.temperature) === -1, 'ml-5': [999, -999].indexOf(signalizer.temperature) > -1, 'align-middle': true}">
          <span v-if="online" class="text-green-500"><SignalOnIcon class="h-4 w-auto inline mb-1"/> ON</span>
          <span v-else class="text-red-600"><SignalOffIcon class="h-4 w-auto inline mb-1"/> OFF</span>
        </span>
        <span class="text-sm text-gray-400 ml-0 md:ml-10 block md:inline-block mb-1 mt-1">
          <span v-if="signalizer.lastActivity">
          Poslední data z {{signalizer.lastActivity | datecs}}
          </span>
          <span v-else>
            <SpinnerIcon class="inline h-4 mb-1"/> Čekání na spojení ...
          </span>
        </span>
      </div> 
      <div class="flex-init">
        <span v-if="renamingSignalizator">
          <input type="text" class="input" v-model="name">
          <span class="block mt-1 md:mt-0 lg:mt-0 md:inline lg:inline">
            <button class="ml-1 btn btn-primary" @click="rename">Uložit</button>
            <button class="ml-1 btn btn-default" @click="closeAll">Storno</button>
          </span>
        </span>
        <span v-if="removingSignalizator">
          <span>Opravdu si přejete odstranit <strong>{{name}}</strong>?</span>
          <div class="block md:inline-block mt-2 md:mt-0">
            <button class="ml-1 btn btn-primary" @click="remove">Odstranit</button>
            <button class="ml-1 btn btn-default" @click="closeAll">Storno</button>
          </div>
        </span>
        <span v-if="!editing">
          <router-link :to="editPage">
            <button class="mt-1 btn btn-default">Nastavení</button>
          </router-link>
          <button class="mt-1 ml-1 btn btn-default" @click="renamingSignalizator=true">Přejmenovat</button>
          <button class="mt-1 ml-1 btn btn-default" @click="removingSignalizator=true">Odstranit</button>
        </span>
      </div>
    </div>
    <div class="input-list w-100">
      <InputCard v-bind:key="input.id" v-for="input in signalizer.inputs" :input="input" :signalizer-id="signalizer.id"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import moment from 'moment'
import InputCard from './InputCard.vue'
import SignalOnIcon from './icons/SignalOn.vue'
import SignalOffIcon from './icons/SignalOff.vue'
import SpinnerIcon from './icons/Spinner.vue'
import ChartIcon from './icons/ChartIcon.vue'
import mix from './mixin'

export default {
  name: 'Signalizator',
  mixins: [mix],
  props: {
      signalizerId: {
        default: 0,
        type: Number
      },
      signalizer: {
        default: null,
        type: Object
      },
      name: {
          default: '',
          type: String
      }
  },  
  data () {
    return {
      renamingSignalizator: false,
      removingSignalizator: false
    }
  },
  methods: {
    ...mapActions({
      'edit': 'signalizer/edit',
      'delete': 'signalizer/delete'
    }),
    rename () {
      this.edit({
        id: this.signalizerId,
        name: this.name
      });
      this.closeAll();
    },
    remove () {
      this.delete(this.signalizerId)
      this.closeAll();
    },
    closeAll () {
      this.renamingSignalizator = false
      this.removingSignalizator = false
    },
    canShow(lastActivity) {
      console.log(moment(new Date()).diff(moment(lastActivity), 'day'))
      return moment(new Date()).diff(moment(lastActivity), 'day') < 7
    }
  },
  computed: {
    online () {
      return ['CONNECTED', 'OVERHEAT', 'UNDERHEAT', 'OK', 'PROBE_DISCONNECTED', 'PROBE_ERR_2'].indexOf(this.signalizer.status) > -1
    },
    currentStateColor () {
      return this.getSignalizatorColor(this.signalizer)
    },
    editing () {
      return this.renamingSignalizator || this.removingSignalizator
    },
    editPage() {
      return `/signalizator-detail/${this.signalizerId}`
    },
    temperaturesPage() {
      return `/temperatures/${this.signalizerId}`
    }
  },
  components: {
    InputCard,
    SignalOnIcon,
    SignalOffIcon,
    SpinnerIcon,
    ChartIcon
  }    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this components only -->
<style scoped>
.input-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-content: space-between;
  grid-auto-rows: 1fr;
}
</style>
