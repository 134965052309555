<template>
  <div>
    <div class="mb-6">
    <ButtonBack />
    </div>
    <div class="text-gray-500 ">{{getSignalizerName()}} / Vstup č. {{input.input}}</div>
    <div class="pb-2 mb-3 mb-10 border-b">
        <button  v-if="!renamingInput" class="float-right ml-1 btn btn-default" @click="rename">Přejmenovat</button>
        <span v-if="!renamingInput" class="text-2xl text-gray-700 font-headings">{{input.name}}</span>
        <span v-if="renamingInput" class="inline-block mt-2">
          <input type="text" class="w-64 input" v-model="name">
          <span class="block mt-1 md:mt-0 lg:mt-0 md:inline lg:inline">
            <button class="ml-1 btn btn-primary" @click="saveEdit">Uložit</button>
            <button class="ml-1 btn btn-default" @click="closeEdit">Storno</button>
          </span>
        </span>        
    </div>    
    <div class="flex flex-wrap justify-between">
      <div class="mb-5 mr-32 flex-init">
        <div class="flex flex-row justify-start gap-1 mb-2">
          <InputStateLabel :currentStateColor="currentStateColor(1)" :currentStateLabel="currentStateLabel(1)" class="text-sm"/>
          <InputStateLabel :currentStateColor="currentStateColor(2)" :currentStateLabel="currentStateLabel(2)" class="text-sm"/>
        </div>
        <div v-if="this.history.length > 0">
          <span class="text-gray-300 ml-2 text-sm"><strong>|</strong></span>
          <span class="text-gray-300 ml-7 text-sm"><strong>|</strong></span>          
        </div>
        <div v-for="h in history" v-bind:key="h">
          <div class="flex flex-row gap-6 justify-between items-start">
            <div>
              <span :aria-label="getInputText(h, 1)" data-microtip-position="top" role="tooltip"  class="mr-2" :class="{ 'text-green-500': h.color1=='green', 'text-red-500': h.color1=='red', 'text-blue-500': h.color1=='blue', 'text-yellow-500': h.color1=='yellow', 'text-purple-500': h.color1=='purple', 'text-gray-400': h.color1=='gray'}">&#11044;</span>
              <span :aria-label="getInputText(h, 2) + (['OVERVOLTAGE', 'UNDERVOLTAGE'].indexOf(h.status) > -1 && h.value ? ` (${h.value}V +/- 5V)` : '')" data-microtip-position="top" role="tooltip" class="ml-1" :class="{ 'text-green-500': h.color2=='green', 'text-red-500': h.color2=='red', 'text-blue-500': h.color2=='blue', 'text-yellow-500': h.color2=='yellow', 'text-purple-500': h.color2=='purple', 'text-gray-400': h.color2=='gray'}">&#11044;</span>
              <div v-if="h.next">
                <span class="text-gray-300 ml-2 text-sm"><strong>|</strong></span>
                <span class="text-gray-300 ml-7 text-sm"><strong>|</strong></span>
              </div>
            </div>            
            <div class="w-28 pt-1">
              <span class="text-sm inline-block text-gray-500 whitespace-nowrap">{{h.createdAt | datecs}}</span>

            </div>
          </div>
        </div>

        <div v-if="next" class="pt-4">
          <button class="btn btn-default" @click="showEvents += 20">Načíst další</button>
        </div>  
      </div>
      <div v-if="loaded" class="flex-grow">
        <TriggerSettings heading="Sepnutí" type="On" default-name="Zapnuto" source="input" default-color="green"/>
        <TriggerSettings heading="Vypnutí" type="Off" default-name="Vypnuto" source="input" default-color="red"/>
        <TriggerSettings heading="Přepětí" type="OVERVOLTAGE" default-name="Přepětí" :enable-value="true" default-color="purple" :default-value="isDc() ? defaultValues.OVERVOLTAGE_DC : defaultValues.OVERVOLTAGE_AC" prefix="Při napětí nad" :suffix="isDc() ? 'V (+/- 0,5V)' : 'V (+/- 5V)'" source="input"/>
        <TriggerSettings heading="Podpětí" type="UNDERVOLTAGE" default-name="Podpětí" :enable-value="true" default-color="blue" :default-value="isDc() ? defaultValues.UNDERVOLTAGE_DC : defaultValues.UNDERVOLTAGE_AC" prefix="Při napětí pod" :suffix="isDc() ? 'V (+/- 0,5V)' : 'V (+/- 5V)'" source="input"/>
        <TriggerSettings heading="Napětí OK" type="OK" default-name="Napětí OK" default-color="green" source="input"/>
      </div>
    </div>                
  </div>
</template>

<script>
import InputStateLabel from './InputStateLabel.vue'
import TriggerSettings from './TriggerSettings.vue'
import ButtonBack from './ButtonBack.vue'
import {mapActions, mapState} from 'vuex'
import mix from './mixin'

export default {
  name: 'InputDetail',
  mixins: [mix],
  data () {
    return {
      renamingInput: false,
      loaded: false,
      next: false,
      name: '',
      showEvents: 20,
      defaultValues: {
        UNDERVOLTAGE_AC: "207",
        UNDERVOLTAGE_DC: "7",
        OVERVOLTAGE_AC: "207",
        OVERVOLTAGE_DC: "32",
      }
    }
  },
  computed: {
    ...mapState({
      'loading': state => state.input.loading,
      'input': state => state.input.input,
      'signalizer': state => state.signalizer.signalizers,
      colors: state => state.user.colors
    }),
    history () {
      const count = this.input.histories.length
      this.next = this.showEvents < count
      let current = 0
      const self = this
      return this.input.histories.map(x => {
        x.next = (current != count - 1)
        x.color1 = self.getInputColor(x, true, 1)
        x.color2 = self.getInputColor(x, true, 2)
        current++;
        return x;
      }).slice(0, this.showEvents)
    }
  },
  methods: {
    ...mapActions({
      'load': 'input/load',
      'edit': 'input/edit',
    }),
    currentStateLabel (index) {
      return this.getInputText(this.input, index)
    },
    currentStateColor (index) {
      return this.getInputColor(this.input, false, index)
    },
    getSignalizerName () {
      const id = this.$route.params.signalizerId
      for (let i of this.signalizer) {
        if (id == i.id) {
          return i.name
        }
      }
      return ''
    },
    isDc () {
      const id = this.$route.params.signalizerId
      for (let i of this.signalizer) {
        if (id == i.id) {
          return i.dc
        }
      }
      return ''
    },
    rename () {
      this.name = this.input.name
      this.renamingInput = true
    },
    closeEdit () {
      this.renamingInput = false
    },
    saveEdit () {
      // todo valid
      this.edit(Object.assign({name: this.name}, this.$route.params))
      this.closeEdit()
    }
  },
  watch: {
    loading (l) {
      if (l == false) {
        this.loaded = true
      }
    }
  },
  created() {
    this.loaded = false
    this.load(this.$route.params)
  },
  components: {
    InputStateLabel,
    TriggerSettings,
    ButtonBack

  }   
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
