<template>
  <div>
    <div class="mt-6 mb-12">
      <button v-if="addingSignalizator == false" class="btn btn-primary" @click="addingSignalizator=true">Přidat signalizátor</button>
      <div v-if="addingSignalizator == true" class="mb-2">
        <span class="flex justify-between md:flex-none md:inline-block mt-1">
          <label >Název:</label>
          <input class="ml-1 input mr-3" type="text" v-model="name">
        </span>
        <span class="flex justify-between md:flex-none md:inline-block mt-1">
          <label>Výrobní číslo:</label>
          <input class="ml-1 input mr-3" type="text" v-model="serialNumber">
        </span>
        <span class="inline-block mt-2 md:mt-0 ml-0 md:ml-1 lg:ml-1">
          <button class="mr-1 btn btn-primary" @click="addSignalizator">Přidat</button>
          <button class="btn btn-default" @click="addingSignalizator=false">Storno</button>
        </span>
      </div>        
    </div> 
    <section class="mb-20" v-if="this.history.length > 0">
      <h2>
        Nedávné události
      </h2>
      <div>
        <div v-for="f in history"  v-bind:key="f.id" class="flex flex-col md:grid md:grid-cols-4 md:gap-10 justify-start items-start mb-4 md:mb-2 hover:bg-gray-50">
          <div class="w-28 py-1">
            <span class="text-sm inline-block text-gray-500 whitespace-nowrap">{{f.createdAt | datecs}}</span>
          </div>
          <div>
            <router-link :to="linkTo(f)">{{f.text}}</router-link>
          </div>          
          <div class="flex flex-row gap-2 items-center">
              <span :aria-label="f.text1" data-microtip-position="top" role="tooltip" class="" :class="{ 'text-green-500': f.color1=='green', 'text-red-500': f.color1=='red', 'text-blue-500': f.color1=='blue', 'text-yellow-500': f.color1=='yellow', 'text-purple-500': f.color1=='purple', 'text-gray-400': f.color1=='gray'}">⬤</span>
              <span>{{f.text1}}</span>
          </div>
          <div  class="flex flex-row gap-2 items-center">
            <span :aria-label="f.text2" data-microtip-position="top" role="tooltip" class="" :class="{ 'text-green-500': f.color2=='green', 'text-red-500': f.color2=='red', 'text-blue-500': f.color2=='blue', 'text-yellow-500': f.color2=='yellow', 'text-purple-500': f.color2=='purple', 'text-gray-400': f.color2=='gray'}">⬤</span>
            <span>{{f.text2}}</span>
            <span class="text-gray-500" v-if="f.status === 'Two' || f.status === 'Three'">({{f.value + (f.type === 'signalizer' ? '°C +/- ' +f.precisionData+ '°C' : 'V +/- ' +f.precisionData+ 'V')}})</span>
          </div>
        </div>
      </div>
      <div v-if="next && showEvents < feed.length" class="pt-4">
        <button class="btn btn-default" @click="showEvents += 10">Načíst další</button>
      </div>
    </section>

    <Signalizator v-bind:key="s.id" v-for="s in signalizer.signalizers"
                  :name="s.name" :signalizer-id="s.id" :signalizer="s"/>
  </div>
</template>

<script>

  import Signalizator from './Signalizator.vue'
  import {mapActions, mapState} from 'vuex'
  import mix from './mixin'

export default {
  name: 'Dashboard',
  mixins: [mix],
  components: {
    Signalizator
  },
  data () {
    return {
      addingSignalizator: false,
      name: '',
      serialNumber: '',
      timer: '',
      feed: [],
      showEvents: 10,
      next: false
    }
  },
  methods: {
    ...mapActions({
      loadSignalizers: 'signalizer/load',
      loadConfig: 'user/loadConfig',
      loadFeed: 'user/loadFeed',
      createSignalizer: 'signalizer/create',
      error: 'alert/error'
    }),
    linkTo(f) {
      if (f.type === 'signalizer') {
        return 'signalizator-detail/' + f.deviceId
      } else if (f.type === 'input') {
        let data = this.findSignalizerByInput(f.deviceId)
        return 'input-detail/' + data.s + '/' + data.i
      }
    },
    findSignalizerByInput(inputId) {
      for (let s of this.signalizer.signalizers) {
        // console.log(s)
        for(let i of s.inputs) {
          // console.log(i)
          if (i.id === inputId) {
            return {
              s: s.id,
              i: i.input
            }
          }
        }
      }
      return {s:0, i:0}
    },
    addSignalizator () {
      const regex = /^[0-9]{3,3}-?[0-9]{3,3}-?[0-9]{3,3}$/

      if (!regex.test(this.serialNumber)) {
        this.error('Neplatný formát sériového čísla signalizátoru.')
        return
      } else if (this.name === '') {
        this.error("Chybý název signalizátoru.")
        return
      }
      this.createSignalizer({
        name: this.name,
        serialNumber: this.serialNumber
      }).then(() => {
        this.addingSignalizator = false
      })
    },
    fetchFeed() {
      this.loadFeed().then(res => {
        // console.log(res)
        this.feed = res.data['_embedded']['feedList'].map(x => {
          if (x.type === 'input') {
            let status = {
              'Zero': 'Off',
              'One': 'On',
              'Two': 'UNDERVOLTAGE',
              'Three': 'OVERVOLTAGE',
              'OK': 'OK'
            }[x.status]
            x.color1 = this.getInputColor({status: status, id: x.deviceId}, false, 1)
            x.text1 = this.getInputText({status: status, id: x.deviceId}, 1)
            x.color2 = this.getInputColor({status: status, id: x.deviceId}, false, 2)
            x.text2 = this.getInputText({status: status, id: x.deviceId}, 2)
          } else if (x.type === 'signalizer') {
            let status = {
              'Zero': 'CONNECTED',
              'One': 'DISCONNECTED',
              'Two': 'OVERHEAT',
              'Three': 'UNDERHEAT',
              'Four': 'PROBE_DISCONNECTED',
              'Five': 'PROBE_ERR_2',
              'OK': 'OK'
            }[x.status]
            x.color1 = this.getSignalizatorColor({status: status, id: x.deviceId}, 1)
            x.text1 = this.getSignalizatorText({status: status, id: x.deviceId}, 1)

            x.color2 = this.getSignalizatorColor({status: status, id: x.deviceId}, 2)
            x.text2 = this.getSignalizatorText({status: status, id: x.deviceId}, 2)
          }
          return x
        })
        if (this.feed.length > 10) {
          this.next = true
        }
      })
    }
  },
  computed: {
    ...mapState({
      signalizer: state => state.signalizer
    }),
    history() {
      return this.feed.slice(0, this.showEvents)
    }
  },
  created () {
    this.loadSignalizers()
    this.loadConfig()
    this.fetchFeed()
    this.timer = setInterval(this.loadSignalizers, 20000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
