<template>
  <div>
    <ButtonBack />  
    <SmsPricing />
    <GopayBanner />
  </div>
</template>

<script>
import ButtonBack from './ButtonBack.vue'
import GopayBanner from './GopayBanner.vue'
import SmsPricing from './SmsPricing.vue'

export default {
  name: 'Buy',
  components: {
    ButtonBack,
    GopayBanner,
    SmsPricing
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
