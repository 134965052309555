<template>
  <div>
    <ButtonBack />
    <h1 class="mt-4">Historie SMS</h1>
    <p class="mb-4">SMS kredity jsou společné pro všechny signalizátory pod účtem <strong>{{user.username}}</strong>.</p>
    <table class="w-full bg-white border table-auto">
      <tr v-for="v in data" class="border">
        <td class="text-gray-500">
          {{v.createdAt|datecs}}
        </td>
        <td>
          {{v.message}}
        </td>
        <td>
          <span :class="v.credits > 0 ? 'text-green-500' : 'text-red-600'">{{v.credits}} {{v.call == true ? 'telefonát' : 'SMS'}} </span>
        </td>                   
      </tr>      
<!--      <tr class="border">-->
<!--        <td class="text-gray-500">-->
<!--          24.10.2019 18:00-->
<!--        </td>-->
<!--        <td>-->
<!--          SMS na +420 124 456 789 <span class="ml-3 text-gray-500">Signalizátor ve sklepě / Vstup č. 3 / Zapnuto</span>-->
<!--        </td>-->
<!--        <td>-->
<!--          <span class="text-red-600">-1 SMS</span>-->
<!--        </td>                   -->
<!--      </tr>-->
<!--      <tr class="border">-->
<!--        <td class="text-gray-500">-->
<!--          24.10.2019 18:00-->
<!--        </td>-->
<!--        <td>-->
<!--          Dobití kreditu-->
<!--        </td>-->
<!--        <td>-->
<!--          <span class="text-green-500">+120 SMS</span>-->
<!--        </td>                   -->
<!--      </tr>      -->
    </table>
  </div>
</template>

<script>
import ButtonBack from './ButtonBack.vue'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'History',
  components: {
    ButtonBack
  },
  data () {
    return {
      data: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.profile.data
    }),
  },
  methods: {
    ...mapActions('user', ['loadHistory'])
  },
  mounted() {
    this.loadHistory().then(res => {
      this.data = res
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table td {
  @apply py-2 px-3;
}
</style>
