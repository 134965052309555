import {inputActionService} from '../_service';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const state = {
    loading: false,
    input: null
}

const actions = {
    load({ commit, dispatch }, data) {
        commit('loading')

        inputActionService.load(data.signalizerId, data.position)
            .then(
                response => commit('loadSuccess', response),
                error => {
                    commit('loadFailed', error)
                    dispatch('alert/error', error, {root: true})
                }
            )
    },
    create({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            inputActionService.create(data.signalizerId, data.position, {
                name: data.name,
                color: capitalizeFirstLetter(data.color),
                sendTo: data.sendTo,
                text: data.text,
                enabled: data.enabled,
                event: data.event,
                delay: data.delay,
                sendImmediately: data.sendImmediately,
                limitValue: Number(data.limitValue),
                sender: data.sender
            }).then(
                response => {
                    dispatch('alert/success', 'Action saved.', {root: true})
                    resolve(response)
                },
                error => {
                    dispatch('alert/error', error, {root: true})
                    reject(error)
                }
            )
        })
    },
    edit({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            inputActionService.edit(data.signalizerId, data.position, data.action, {
                name: data.name,
                color: capitalizeFirstLetter(data.color),
                sendTo: data.sendTo,
                text: data.text,
                enabled: data.enabled,
                event: data.event,
                sender: data.sender,
                delay: data.delay,
                sendImmediately: data.sendImmediately,
                limitValue: Number(data.limitValue)
            }).then(
                response => {
                    dispatch('alert/success', 'Action saved.', {root: true})
                    resolve(response)
                },
                error => {
                    dispatch('alert/error', error, {root: true})
                    reject(error)
                }
            )
        })

    },
    patch({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            inputActionService.patch(data.signalizerId, data.position, {
                status: data.status,
                color: capitalizeFirstLetter(data.color),
                limit: Number(data.limit)
            }).then(
                response => {
                    dispatch('alert/success', 'Action saved.', {root: true})
                    resolve(response)
                },
                error => {
                    dispatch('alert/error', error, {root: true})
                    reject(error)
                }
            )
        })

    },
    delete({ commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            inputActionService.delete(data.signalizerId, data.position, data.action).then(
                response => {
                    dispatch('alert/success', 'Action deleted.', {root: true})
                    resolve(response)
                },
                error => {
                    // dispatch('alert/error', error, {root: true})
                    reject(error)
                }
            )
        })
    }
}

const mutations = {
    loading (state) {
        state.loading = true
    },
    loadSuccess(state, data) {
        state.input = data.data
        state.loading = false
    },
    loadFailed(state, error) {
        state.input = null
        state.loading = false
    }
}

export const inputAction = {
    namespaced: true,
    state,
    actions,
    mutations
}