<template>

<div id="dropdown" class="relative inline-block">
  <button
    @click="isOpen = true"
    class="inline-flex items-center pl-3 pr-0 mt-1 bg-white btn btn-default"
  >
    <div>{{user.username}}</div>
    <svg
      class="w-6 h-6 ml-2 text-gray-400"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
      />
    </svg>
  </button>

  <div
    v-if="isOpen"
    @click="isOpen = false"
    class="fixed inset-0"
    tabindex="-1"
  ></div>

  <transition
    enter-class="opacity-0 scale-90"
    enter-active-class="ease-out transition-fastest"
    enter-to-class="opacity-100 scale-100"
    leave-class="opacity-100 scale-100"
    leave-active-class="ease-in transition-fastest"
    leave-to-class="opacity-0 scale-90"
  >
    <div
      v-if="isOpen"
      class="absolute right-0 z-50 mt-2 text-left origin-top-right"
    >
      <div class="w-64 bg-white rounded-lg shadow-lg">
        <div class="py-1 border-gray-100">
          <router-link to="/history">
            <span class="block px-6 py-3 leading-tight hover:bg-gray-100" @click="isOpen = false">
            Historie SMS
            </span>
          </router-link>
          <router-link to="/buy">
            <span class="block px-6 py-3 leading-tight hover:bg-gray-100" @click="isOpen = false">
              Přikoupit SMS
            </span>
          </router-link>
         <router-link v-if="!isLoggedIn" to="/reset-password">
            <span class="block px-6 py-3 leading-tight hover:bg-gray-100" @click="isOpen = false">
              Změnit heslo
            </span>
          </router-link>
          <span class="block px-6 py-3 leading-tight hover:bg-gray-100 cursor-pointer" @click="logoutAction">
            Odhlásit
          </span>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>

  import {mapActions, mapState} from 'vuex'

  export default {
    name: 'Dropdown',
    data () {
      return {
        isOpen: false
      }
    },
    methods: {
      ...mapActions({
        logout: 'user/logout'
      }),
      logoutAction () {
        this.logout()
        this.isOpen = false
      }
    },
    computed: {
      ...mapState({
        user: state => state.user.profile.data,
        isLoggedIn: state => state.user.profile.status == 200
      }),
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a:hover {
  text-decoration: none;
}
</style>
