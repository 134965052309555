<template>
  <div>
    <div class="mb-6">
      <ButtonBack />
    </div>
    <div class="pb-2 mb-3 mb-10 border-b">
      <span class="text-2xl text-gray-700 font-headings">{{ signalizer.name }}</span>
    </div>
    <div class="flex flex-wrap md:flex-nowrap justify-center">
      <div class="mb-5 flex-init">
        <div class="chart-container m-auto" style="position: relative; width:80vw"><canvas
            id="temperature-history"></canvas></div>
      </div>
    </div>

  </div>
</template>

<script>

import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from 'moment';
import Vue from 'vue'
import ButtonBack from './ButtonBack.vue'
import { mapActions, mapState } from 'vuex'
import mix from './mixin'

export default {
  name: 'SignalizatorTemperatures',
  mixins: [mix],
  data() {
    return {
      chart: null,
      showEvents: 20,
      rendered: false,
      interval: null,
    }
  },
  computed: {
    ...mapState({
      'signalizers': state => state.signalizer.signalizers,
      'temperatures': state => state.signalizer.temperatures
    }),
    signalizer() {
      for (let s of this.signalizers) {
        if (s.id == this.$route.params.signalizerId) {
          Vue.nextTick(() => {
            this.loaded = true
          })
          return s
        }
      }
    },
  },
  methods: {
    ...mapActions({
      'loadTemperatures': 'signalizer/temperatures'
    }),
    createChart() {
      const data = this.temperatures.data;
      if (data.length == 0) {
        return
      }
      this.chart = new Chart(
        document.getElementById('temperature-history'),
        {
          type: 'line',
          data: {
            labels: data.map(row => row.createdAt),
            datasets: [
              {
                label: 'Teplota',
                data: data.map(row => row.value),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgb(75, 192, 192)',
                borderWidth: 1,
                pointBorderWidth: 1,
                pointRadius: 0,
              }
            ]
          },
          options: {
            animation: false,
            spanGaps: true,
            aspectRatio: 3,
            locale: 'cs-CZ',
            interaction: {
              mode: 'index',
              intersect: false
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day'
                },
                min: new Date(data[data.length - 1].createdAt).valueOf(),
                max: new Date(data[0].createdAt).valueOf()
              },
              y: {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, ticks) {
                    return value + '°C';
                  }
                },
                min: Math.min(...data.map(o => o.value)) - 10,
                max: Math.max(...data.map(o => o.value)) + 10
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y + '°C';
                    }
                    return label;
                  }
                }
              },
              legend: {
                display: false
              }
            }
          }
        }
      );
      this.rendered = true
    }
  },
  mounted() {
    moment.locale("cs");
    // console.log(this.$route.params)
    this.loadTemperatures(this.$route.params.signalizerId)
    this.interval = setInterval(() => {
      this.createChart()
      if (this.rendered) {
        clearInterval(this.interval)
      }
    }, 5000)
  },
  components: {
    ButtonBack

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
