import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/tailwind.pcss'
import router from '@/router/index.js'
import moment from 'moment'
import ToggleButton from 'vue-js-toggle-button'
import Vuex from 'vuex'
import microtip from 'microtip/microtip.css'

Vue.filter('normalizeSerialNumber', function (value) {
    if (!value) return ''
    value = value.toString().replace(/-/ig, '')
    return value.substr(0, 3) + '-' + value.substr(3, 3) + '-' + value.substr(6, 3)
})

Vue.filter('datecs', function (value) {
    return moment(value).format('DD.MM.YYYY HH:mm:ss')
})

import store from './_store'

Vue.use(ToggleButton)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>',
    render: h => h(App)
})
