import {signalizerActionService} from '../_service';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const state = {
    loading: false,
    input: null
}

const actions = {
    load({ commit, dispatch }, data) {
        commit('loading')

        signalizerActionService.load(data.signalizerId)
            .then(
                response => commit('loadSuccess', response),
                error => {
                    commit('loadFailed', error)
                    dispatch('alert/error', error, {root: true})
                }
            )
    },
    create({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            console.log(data)
            signalizerActionService.create(data.signalizerId, {
                name: data.name,
                color: capitalizeFirstLetter(data.color),
                sendTo: data.sendTo,
                text: data.text,
                enabled: data.enabled,
                event: data.event,
                delay: data.delay,
                sendImmediately: data.sendImmediately,
                limitValue: Number(data.limitValue),
                sender: data.sender,
                file: data.file
            }).then(
                response => {
                    dispatch('alert/success', 'Action saved.', {root: true})
                    // dispatch('signalizer/load',null, {root: true})
                    // commit('loadSuccess', response)
                    resolve(response)
                },
                error => {
                    // dispatch('alert/error', error, {root: true})
                    reject(error)
                }
            )
        })

    },
    edit({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            signalizerActionService.edit(data.signalizerId, data.action, {
                name: data.name,
                color: capitalizeFirstLetter(data.color),
                sendTo: data.sendTo,
                text: data.text,
                enabled: data.enabled,
                event: data.event,
                sender: data.sender,
                delay: data.delay,
                sendImmediately: data.sendImmediately,
                limitValue: Number(data.limitValue)
            }).then(
                response => {
                    dispatch('alert/success', 'Action saved.', {root: true})
                    // dispatch('signalizer/load', null, {root: true})
                    resolve(response)
                },
                error => {
                    // dispatch('alert/error', error, {root: true})
                    reject(error)
                }
            )
        })
    },
    delete({ commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            signalizerActionService.delete(data.signalizerId, data.action).then(
                response => {
                    dispatch('alert/success', 'Action deleted.', {root: true})
                    // dispatch('signalizer/load', {root: true})
                    // commit('loadSuccess', response)
                    resolve(response)

                },
                error => {
                    // dispatch('alert/error', error, {root: true})
                    reject(error)
                }
            )
        })
    }
}

const mutations = {
    loading (state) {
        state.loading = true
    },
    loadSuccess(state, data) {
        state.input = data.data
        state.loading = false
    },
    loadFailed(state, error) {
        state.input = null
        state.loading = false
    }
}

export const signalizerAction = {
    namespaced: true,
    state,
    actions,
    mutations
}