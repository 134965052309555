import {inputService} from '../_service';

const state = {
    loading: false,
    input: null
}

const actions = {
    load({ commit, dispatch }, data) {
        commit('loading')

        inputService.load(data.signalizerId, data.position)
            .then(
                response => commit('loadSuccess', response),
                error => {
                    commit('loadFailed', error)
                    dispatch('alert/error', error, {root: true})
                }
            )
    },
    edit({commit, dispatch}, data) {
        inputService.edit(data.signalizerId, data.position, {
            name: data.name
        }).then(
            response => {
                dispatch('alert/success', 'Vstup přejmenován', {root: true})
                commit('loadSuccess', response)
            },
            error => {
                dispatch('alert/error', error, {root: true})
            }
        )
    }
}

const mutations = {
    loading (state) {
        state.loading = true
    },
    loadSuccess(state, data) {
        state.input = data.data
        state.loading = false
    },
    loadFailed(state, error) {
        state.input = null
        state.loading = false
    }
}

export const input = {
    namespaced: true,
    state,
    actions,
    mutations
}