<template>
    <div class="status inline-block px-3 py-1 text-center uppercase tracking-wide text-opacity-75 text-white rounded-full" :class="{ 'bg-green-500': currentStateColor=='green', 'bg-red-500': currentStateColor=='red', 'bg-blue-500': currentStateColor=='blue', 'bg-yellow-500': currentStateColor=='yellow', 'bg-purple-500': currentStateColor=='purple', 'bg-gray-400': currentStateColor=='gray' }" >
       {{currentStateLabel}}
    </div>
</template>

<script>
export default {
  name: 'InputCard',
  props: {
      currentStateColor: {
          default: 'gray',
          type: String
      },
      currentStateLabel: {
          default: '',
          type: String
      }                           
  }
}
</script>

<style scoped>
.status {
  min-height: 24px;
}
</style>
