<template>
  <div class="relative p-4 text-center align-bottom bg-white border rounded shadow">
    <span class="absolute flex flex-col justify-center text-center input-number rounded-full bg-white text-gray-500 border border-gray-200" >
      <span class="input-number-value">{{input.input}}</span>
    </span>
    <div class="flex flex-col justify-between h-full">
      <div>
        <div v-if="empty" >
          <div class="mb-10 text-center text-gray-400">Vstup č. {{input.input}}</div>
        </div>
        <div v-else>
          <div class="mb-2 text-center">{{input.name}}</div>
        </div>        
      </div>  
      <div>
        <div class="mb-3 flex flex-col gap-1">
          <div>
            <InputStateLabel :currentStateColor="currentStateColor(1)" :currentStateLabel="currentStateLabel(1)" class="text-xs"/>
          </div>
          <div>
            <InputStateLabel :currentStateColor="currentStateColor(2)" :currentStateLabel="currentStateLabel(2)" class="text-xs"/>
          </div>
        </div>
<!--        <div class="mb-3 text-xs text-gray-500 uppercase tracking-wider">-->
<!--          &lt;!&ndash; TODO: Navázat aktuální stav přepětí / podpětí &ndash;&gt;-->
<!--          <LightningIcon v-if="input.status != 'Off'" class="h-4 w-auto inline text-yellow-500"/>-->
<!--          <span v-if="input.status == 'On' || input.status == 'OK'">OK</span>-->
<!--          &lt;!&ndash; TODO: Vzít barvu textu z nastavení barvy daného stavu &ndash;&gt;-->
<!--           <span v-else-if="input.status == 'OVERVOLTAGE'"><strong>Přepětí!</strong></span>-->
<!--           <span v-else-if="input.status == 'UNDERVOLTAGE'"><strong>Podpětí!</strong></span>-->
<!--        </div>-->
        <router-link :to="editPage">
          <button class="btn btn-default">Nastavení</button>
        </router-link>    
      </div>
    </div>
  </div>
</template>

<script>
import InputStateLabel from './InputStateLabel.vue'
import LightningIcon from './icons/Lightning.vue'
import { mapState } from 'vuex'
import mix from './mixin'

export default {
  name: 'InputCard',
  mixins: [mix],
  props: {
      input: {
        default: null,
        type: Object
      },
      signalizerId: {
        default: 0,
        type: Number
      }
  },
  computed: {
    empty () {
      return false
    },
    editPage () {
      return `/input-detail/${this.signalizerId}/${this.input.input}`
    },
    ...mapState({
      colors: state => state.user.colors
    }),
  },
  methods: {

    currentStateLabel (index) {
      return this.getInputText(this.input, index)
    },
    currentStateColor (index) {
      return this.getInputColor(this.input, false, index)
    }
  },
  components: {
    InputStateLabel,
    LightningIcon
  }  
}
</script>

<style scoped>

.input-number {
    font-variant-numeric: tabular-nums;
    width: 30px;
    height: 30px;
    top:-8px;
    left:-8px;
}
</style>
