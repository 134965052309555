<template>
  <div id="app">
    <Header :isLoggedIn=true />
    <div v-if="alert.message" :class="`${alert.type} border-t border-b px-4 py-3 text-center`" role="alert">
      <!-- <p class="font-bold">{{alert.title}}</p> -->
      <p class="text-sm">{{alert.message}}</p>
    </div>
    <div class="page-content">
      <div class="container mx-auto pt-3 pb-10">
        <router-view/>
      </div>
    </div>
    <Footer />
    <!-- <router-view v-on:changeRoute="currentRoute = $route.path"/> -->
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import {mapActions, mapState} from 'vuex'
  import router from "@/router";
  import Vue from 'vue';

  export default {
    name: 'app',
    components: {
      Header,
      Footer
    },
    computed: {
      ...mapState({
        alert: state => state.alert,
        profile: state => state.profile
      })
    },
    methods: {
      ...mapActions({
        loadProfile: 'user/loadProfile',
        clearAlert: 'alert/clear'
      })
    },
    watch: {
      $route (to, from) {
        // clear alert on location change
        this.clearAlert();
      },
      profile (profile) {
        // console.log(profile)
      }
    },
    mounted() {
      Vue.nextTick(() => {
        for (let v of ['/login-form', '/registration', '/reset-password', '/change-password', '/check-signalizator']) {
          if (this.$route.path.indexOf(v) > -1) {
            return false;
          }
        }
        this.loadProfile()
      })
    }
  }
</script>
<style scoped>
  .success-message {
    @apply bg-green-100 border-green-500 text-green-700
  }
  .error-message {
    @apply bg-red-100 border-red-500 text-red-700
  }
</style>