<template>
  <div class="mb-4 bg-white border rounded shadow">
    <div class="px-4 py-2 pb-2 text-gray-600 rounded rounded-b-none bg-gray-50 border-b border-gray-100">
      <span class="tracking-wide uppercase text-sm font-bold">{{heading}}</span> 
    </div>
    <div class="p-4">
      <div class="mb-4">
        <label class="inline-block w-31 mr-5">Popis stavu:</label>
        <input type="text" class="mr-1 input" v-model="name">
        <!-- TODO: Nahled barev primo v selectu misto textu -->
        <select class="mt-1 input md:mt-0 lg:mt-0 pb-2" v-model="color" :class="{ 
            'text-green-500': (color == 'green'), 
            'text-red-500': (color == 'red'), 
            'text-blue-500': (color == 'blue'), 
            'text-yellow-500': (color == 'yellow'),
            'text-purple-500': (color == 'purple'),
            'text-gray-400': (color == 'green')}
          ">
          <option value="green" class="text-green-500">	&#11044; Zelená</option>
          <option value="red" class="text-red-500">	&#11044; Červená</option>
          <option value="blue" class="text-blue-500">	&#11044; Modrá</option>
          <option value="yellow" class="text-yellow-500">	&#11044; Žlutá</option>
          <option value="purple" class="text-purple-500">	&#11044; Fialová</option>
          <option value="gray" class="text-gray-400">	&#11044; Šedá</option>
        </select>
      </div>
      <div v-if="enableValue" class="mb-4">
        <span class="text-red-600 block" v-if="errorValue">{{errorMessage}}</span>
        <label class="inline-block w-30 mr-1">{{prefix}} </label>
        <input type="text" :class="{'ml-1': true, 'input': true, 'border-red-600': errorValue }" v-model="limitValue"> <span class="text-sm text-gray-500">{{suffix}}</span>
      </div>
      <div class="mb-4">

      </div>
      <div class="mb-4">
        <div v-for="sEvent in loadedEvents" v-bind:key="sEvent.id">
          <div class="mb-6 flex flex-col md:flex-row gap-3">
            <span class="flex flex-col justify-start mt-6">
              <toggle-button :sync="true" v-model="sEvent.enabled"/>
            </span>
            <span class="flex flex-col justify-start">
              <div>
                <small>Odeslat</small>
              </div>
              <div class="flex flex-row gap-1 place-items-center">
                <select v-model="sEvent.sendImmediately" class="input">
                  <option :value="true">
                    Ihned
                  </option>
                  <option :value="false">
                    Až když trvá déle než
                  </option>
                </select>
                <input v-if="!sEvent.sendImmediately" type="number" min="1" class="input w-16" v-model="sEvent.delay"/>
                <span v-if="!sEvent.sendImmediately">minut</span>
              </div>
            </span>
            <span class="flex flex-col justify-start">
              <div v-if="sEvent.sender === 'SMS' || sEvent.sender === 'Call'">
                <small>Telefonní číslo vč. mezinárodní předvolby</small>
              </div>
              <div v-if="sEvent.sender === 'Email'">
                <small>Emailová adresa</small>                
              </div>              
              <input type="text" v-model="sEvent.sendTo" class="w-full input">
            </span>
            <span class="flex-grow flex flex-col justify-start">
              <div v-if="sEvent.sender === 'SMS'">
                <small>Text SMS</small>
              </div>
              <div v-if="sEvent.sender === 'Email'">
                <small>Text emailu</small>                
              </div>
              <div v-if="sEvent.sender === 'Call'">
                <small>Nahrávka (max. 20 s)</small>
              </div>
              <RecordAudio v-if="sEvent.sender === 'Call'" :url="sEvent.audioUrl" @audioReady="handleFileUpload($event, sEvent.id)" />
              <input v-else type="text" class="inline-block input w-full" v-model="sEvent.text">
            </span>
            <span class="inline md:flex md:flex-col justify-end">
              <button class="py-2 mb-0 ml-1 align-middle btn btn-default" @click="remove(sEvent)">
                <svg class="h-4 text-gray-600" aria-hidden="true" focusable="false" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path></svg>
              </button>              
            </span>
          </div>
        </div>
        <div class="flex gap-1">
          <button class="btn btn-default" @click="add('telephoneService')">Přidat SMS</button>
          <button class="btn btn-default" @click="add('email')" data-microtip-position="top" role="tooltip"  >Přidat email</button>
          <button class="btn btn-default" @click="add('call')" data-microtip-position="top" role="tooltip" > Přidat telefonát</button>

            <span aria-label="Tip: Pro zajištění maximální spolehlivosti si uložte do kontaktů číslo +420 570 000 016. Z tohoto čísla bude telefonát probíhat."
               data-microtip-position="top" role="tooltip"  class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
</svg>
</span>
        </div>
      </div>
      <div class="pt-2 border-t">
        <button class="btn btn-primary" @click="save">Uložit</button>
        <span v-if="savingFinished" class="ml-2 text-green-500">
          <svg class="inline-block h-4" aria-hidden="true" focusable="false" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
          <span class="ml-1">Uloženo</span>
        </span>
        <!-- TODO: Skrýt text Uloženo po 5 vteřinách -->
        <!-- TODO: Překreslit po uložení log v levé části screenu InputDetail-->
      </div>  
    </div>                   
  </div> 
</template>

<script>

  import { mapActions, mapState } from 'vuex'
  import RecordAudio from "@/components/RecordAudio";

export default {
  name: 'TriggerSettings',
  components: {RecordAudio},
  props: {
      heading: {
          default: '',
          type: String
      },
      type: {
        default: '',
        type: String
      },
      source: {
        default: '',
        type: String
      },
      enableValue: {
        default: false,
        type: Boolean
      },
      defaultName: {
        default: '',
        type: String
      },
      prefix: {
        default: 'Hodnota',
        type: String
      },
      suffix: {
        default: '',
        type: String
      },
      defaultColor: {
        default: 'grey',
        type: String
      },
      defaultValue: {
          default: "0",
          type: String
      },
      maxValue: {
        default: 1000,
        type: Number
      },
      minValue: {
        default: -1000,
        type: Number
      }
  },
  data () {
    return {
      savingFinished: false,
      loadedEvents: [],
      deleteEvents: [],
      name: '',
      color: 'none',
      limitValue: -100,
      errorValue: false,
      errorMessage: '',
      tooltipShow: false
    }
  },
  // watch: {
  //   events (events) {
  //     this.loadedEvents = events
  //     this.sendSMS = events != null && events.length > 0 && events[0].enabled
  //     if (events && events.length > 0) {
  //       this.color = events[0].color.toLowerCase()
  //       this.name = events[0].name
  //       if (events[0].limitValue != null) {
  //         this.limitValue = events[0].limitValue
  //       }
  //     }
  //   }
  // },
  computed: {
    ...mapState({
      'input': state => state.input.input,
      'signalizers': state => state.signalizer.signalizers,
    }),
    signalizer () {
      for (let s of this.signalizers) {
        if (s.id == this.$route.params.signalizerId) {
          return s
        }
      }
    },
    actions () {
      return this.signalizer.actions
    },
    events () {
      // console.log('events')
      const events = []
      if (this.source === 'input') {
        for (let i of this.input.inputActions) {
          if (i.event === this.type) {
            events.push(i)
          }
        }
      } else if (this.source === 'signalizer') {
        for (let i of this.actions) {
          if (i.event === this.type) {
            events.push(i)
          }
        }
      }
      this.loadedEvents = events
      // console.log('TTTTTTTTTTT')
      // console.log(events)
      // console.log('EEEEEEEEEEE')
      if (events && events.length > 0) {
        this.color = events[0].color.toLowerCase()
        this.name = events[0].name
        if (events[0].limitValue != null) {
          this.limitValue = events[0].limitValue
        }
      }
      return events
    }
  },
  methods: {
    ...mapActions({
      'loadInput': 'input/load',
      'createActionInput': 'inputAction/create',
      'updateInput': 'inputAction/patch',
      'editActionInput': 'inputAction/edit',
      'deleteActionInput': 'inputAction/delete',
      'loadSignalizers': 'signalizer/load',
      'createActionSignalizer': 'signalizerAction/create',
      'editActionSignalizer': 'signalizerAction/edit',
      'deleteActionSignalizer': 'signalizerAction/delete',
      'uploadFile': 'signalizer/uploadFile',
      loadConfig: 'user/loadConfig',
    }),
    async save () {
      // console.log(this.loadedEvents)
      if (this.source == 'input') {
        await this.updateInput({
          signalizerId: this.$route.params.signalizerId,
          position: this.$route.params.position,
          status: this.type,
          color: this.color,
          limit: this.limitValue
        })
      }
      const lv = this.limitValue
      if (this.enableValue) {
          if (lv < this.minValue || lv > this.maxValue) {
              this.errorValue = true
              this.errorMessage = `Povolený rozsah je od ${this.minValue} do ${this.maxValue} °C`
              return
          } else {
            this.errorValue = false
          }
      }
      if (this.loadedEvents.length === 0) {
        // this.add({}, false)
      }
      for (let sEvent of this.loadedEvents) {
        if (sEvent.sender === 'Call' && sEvent.file != null) {
          const data = await this.uploadFile({file: sEvent.file})
          sEvent.text = data
        }
        const data = {
          limitValue: lv,
          signalizerId: this.$route.params.signalizerId,
          position: this.$route.params.position,
          action: sEvent.id,
          name: this.name,
          color: this.color,
          sendTo: sEvent.sendTo,
          text: sEvent.text,
          enabled: sEvent.enabled,
          event: sEvent.event,
          sender:sEvent.sender,
          delay: sEvent.delay,
          sendImmediately: sEvent.sendImmediately,
          file: sEvent.file
        }
        // console.log('--------- S')
        // console.log(data)
        // console.log('--------- E')
        if (sEvent.saved === false) {

          switch (this.source) {
              case "input":
                await this.createActionInput(data)
                  break
              case "signalizer":
                await this.createActionSignalizer(data)
          }

        } else {
          switch (this.source) {
            case "input":
              await this.editActionInput(data)
              break
            case "signalizer":
              await this.editActionSignalizer(data)
          }
        }
      }
      // console.log("Deleting")
      for (let event of this.deleteEvents) {
        if (typeof event.id == 'string' && event.id.indexOf('ud') === 0) {
          continue
        }
        switch (this.source) {
          case "input":
            await this.deleteActionInput({
              signalizerId: this.$route.params.signalizerId,
              position: this.$route.params.position,
              action: event.id,
            }).then(() => {
              console.log("input deleted")
            })
            break
          case "signalizer":
            await this.deleteActionSignalizer({
              signalizerId: this.$route.params.signalizerId,
              position: this.$route.params.position,
              action: event.id,
            }).then(() => {
              console.log("signalizer deleted")
            })
        }
      }
      this.deleteEvents.length = 0
      this.savingFinished = true
      const self = this;
      setTimeout(() => {
        self.savingFinished = false
      }, 5000)
      await self.load()
    },
    handleFileUpload(file, id) {
      console.log("DATA:")
      console.log(event)
      console.log(id)
      console.log("////")
      for (let event of this.events) {
        console.log(event)
        console.log(event.id + ' ' + id)
        console.log(event.id == id)
        if (event.id == id) {
          event.file = file
        }
      }
    },
    add (type, enabled = true) {
      let sender = 0
      if (type === 'telephoneService') {
        sender = 'SMS'
      }else if (type === 'call') {
        sender = 'Call'
      } else {
        sender = 'Email'
      }
      this.loadedEvents.push({
        id: 'ud' + Math.floor(Math.random() * 1000000),
        text: '',
        sentTo: '',
        event: this.type,
        sender: sender,
        saved: false,
        color: this.color,
        limitValue: this.limitValue,
        sendImmediately: true,
        delay: 10,
        enabled: enabled,
        file: null
      })
    },
    remove (sEvent) {
      this.deleteEvents.push(sEvent)
      this.loadedEvents = this.loadedEvents.filter(x => x.id !== sEvent.id)
    },
    load () {
      if (this.source === 'input') {
        this.loadInput(this.$route.params)
      } else if (this.source === 'signalizer') {
        this.loadSignalizers()
      }
      this.loadConfig()
    }
  },
  created() {
    if (this.limitValue === -100) {
      this.limitValue = this.defaultValue
    }
    if (this.color === 'none') {
      this.color = this.defaultColor
    }
    if (this.name === '') {
      this.name = this.defaultName
    }
    this.events
  }
}
</script>