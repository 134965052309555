import Vue from 'vue'
import Vuex from 'vuex'

import vuejsStorage from 'vuejs-storage'

Vue.use(Vuex)


import {user} from './user.module'
import {alert} from './alert.module'
import {signalizer} from './signalizer.module'
import {input} from './input.module'
import {inputAction} from './inputAction.module'
import {signalizerAction} from './signalizerAction.module'

export default new Vuex.Store({
    // state,
    // getters,
    // actions,
    // mutations,
    modules: {
        alert,
        user,
        signalizer,
        input,
        inputAction,
        signalizerAction
    },
    // plugins: [
    //     vuejsStorage({
    //         namespace: 'signalizator',
    //         keys: ['user','signalizer']
    //         // keep both root's state.a & moduleA's state
    //     })
    // ]
})