import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Dashboard from '@/components/Dashboard'
import InputDetail from '@/components/InputDetail'
import SignalizatorDetail from '../components/SignalizatorDetail'
import SignalizatorTemperatures from '../components/SignalizatorTemperatures'
import History from '@/components/History'
import Buy from '@/components/Buy'
import ChangePassword from '@/components/ChangePassword'
import ResetPassword from '@/components/ResetPassword'
import Registration from '@/components/Registration'
import CheckSignalizator from '@/components/CheckSignalizator'

Vue.use(Router)

export default new Router ({
  routes: [
    {
      path: '/',
      component: Login
    },
    {
      path: '/registration',
      component: Registration
    },
    {
      path: '/login-form',
      component: Login
    },
    {
      path: '/dashboard',
      component: Dashboard
    },
    {
      path: '/input-detail/:signalizerId/:position',
      component: InputDetail
    },
    {
      path: '/signalizator-detail/:signalizerId',
      component: SignalizatorDetail
    },
    {
      path: '/temperatures/:signalizerId',
      component: SignalizatorTemperatures
    },
    {
      path: '/history',
      component: History
    },
    {
      path: '/buy',
      component: Buy
    },
    {
      path: '/change-password/:token',
      component: ChangePassword
    },
    {
      path: '/reset-password',
      component: ResetPassword
    },
    {
      path: '/check-signalizator',
      component: CheckSignalizator
    }
  ]
})