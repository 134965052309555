import {signalizerService} from '../_service';
import moment from 'moment';

const state = {
    loaded: false,
    signalizers: [],
    history: {
        loading: false,
        data: []
    },
    temperatures: {
        loading: false,
        data: []
    }
}

const actions = {
    load({ commit, dispatch }) {
        commit('loading')

        signalizerService.load()
            .then(
                response => commit('loadSuccess', response),
                error => {
                    commit('loadFailed', error)
                    dispatch('alert/error', error, {root: true})
                }
            )
    },
    async uploadFile({ commit, dispatch }, data) {
        commit('uploading')
        try {
            const response = await signalizerService.uploadFile(data)
            commit('uploadSuccess', response)
            return response.data
        } catch (error) {
            commit('uploadFailed', error)
            dispatch('alert/error', error, {root: true})
        }

    },
    create({commit, dispatch}, data) {
        signalizerService.create(data)
            .then(
                response => {
                    const creditStatus = response.data.creditStatus
                    let message = 'Signalizátor přidán'
                    if (creditStatus === -1) {
                        message = 'Signalizátor není dostupný. Zkontrolujte napájení, vloženou SIM kartu, případně sílu mobilního signálu v jeho okolí.'
                    } else if (creditStatus === 0) {
                        message = 'Signalizátor úspěšně přidán. Tento signalizátor byl již dříve přidán, kredity zdarma nebyly připsány.'
                    } else if (creditStatus === 1) {
                        message = 'Signalizátor byl úspěšně přidán. Do vašeho účtu bylo přidáno 30 SMS kreditů zdarma.'
                    }
                    dispatch('alert/success', message, {root: true})
                    dispatch('load')
                    // commit('add', response.data)
                },
                error => {
                    dispatch('alert/error', error.response.data.message, {root: true})
                }
            )
    },
    edit({commit, dispatch}, data) {
        signalizerService.edit(data.id, {
            name: data.name
        }).then(
            response => {
                dispatch('alert/success', 'Signalizátor přejmenován', {root: true})
                dispatch('load')
            },
            error => {
                dispatch('alert/error', error, {root: true})
            }
        )
    },
    check({commit, dispatch}, data) {
        signalizerService.check(data.serialNumber).then(
            response => {
                // dispatch('load')
                if (response.data.lastActivity) {
                    dispatch('alert/success', 'Poslední aktivita signalizátoru ' + data.serialNumber + ': ' + moment.utc(response.data.lastActivity).format('DD.MM.YYYY HH:mm'), {root: true})
                } else {
                    dispatch('alert/error', 'Signalizátor není aktivní.', {root: true})
                }
                
            },
            error => {
                dispatch('alert/error', error, {root: true})
            }
        )
    },
    delete({commit, dispatch}, id) {
        signalizerService.delete(id)
            .then(
                response => {
                    dispatch('alert/success', 'Signalizátor odebrán', {root: true})
                    dispatch('load')
                },
                error => {
                    dispatch('alert/error', error, {root: true})
                }
            )
    },
    history({commit, dispatch}, id) {
        commit('historyLoading')
        signalizerService.history(id)
            .then(
                response => {
                    commit('historyLoaded', response.data._embedded.inputHistoryList)
                },
                error => {
                    dispatch('alert/error', error, {root: true})
                }
            )
    },
    async temperatures({commit, dispatch}, id) {
        commit('temperaturesLoading')
        signalizerService.temperatures(id)
            .then(
                response => {
                    commit('temperaturesLoaded', response.data._embedded.temperatureList)
                },
                error => {
                    dispatch('alert/error', error, {root: true})
                }
            )
    }
}

const mutations = {
    uploading (state) {

    },
    uploadSuccess (state, data) {
        console.log(data)
    },
    uploadFailed (state) {

    },
    loading (state) {

    },
    historyLoading(state) {
        state.history.loading = true
    },
    historyLoaded(state, data) {
        state.history.loading = false
        state.history.data = data
    },
    temperaturesLoading(state) {
        state.temperatures.loading = true
    },
    temperaturesLoaded(state, data) {
        state.temperatures.loading = false
        state.temperatures.data = data
    },
    loadSuccess(state, data) {
        if (data.data._embedded) {
            state.signalizers = data.data._embedded.signalizerList
        }
        state.loaded = true
    },
    loadFailed(state, error) {
        const status = error.response.status
        state.signalizers = []
        state.loaded = false
    },
    add(state, data) {
        state.signalizers.push(data)
    }
}

export const signalizer = {
    namespaced: true,
    state,
    actions,
    mutations
}