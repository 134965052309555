import { userService } from '../_service'
import router from '../router';

const state = {
    profile: {},
    colors: []
}

const actions = {
    async loadProfile ({ commit }) {
        commit('loadProfileRequest')
        try {
            const response = await userService.profile()
            commit('loadProfileSuccess', response.data)
        } catch(error) {
            commit('loadProfileFailure', error)
            throw error
        }
    },
    async loadHistory ({ commit }) {
        try {
            const data = await userService.history();
            // console.log(data.data['_embedded']['sMSHistoryList'])
            return data.data['_embedded']['sMSHistoryList']
        } catch (e) {
            throw e
        }
    },
    loadConfig ({ commit }) {

        userService.config()
            .then(
                response => commit('loadConfigSuccess', response.data),
                error => {}
            )
    },
    async loadFeed ({ commit }) {
        return userService.feed()
    },
    async login({ commit, dispatch }, data) {
        try {
            const response = await userService.login(data)
            dispatch('loadProfile')

        } catch (error) {
            dispatch('alert/error', 'Spatné jméno a heslo', {root: true});
            throw error
        }
    },
    async reset({ commit, dispatch }, data) {
        try {
            const response = await userService.reset(data)

        } catch (error) {
            dispatch('alert/error', 'Něco se pokazilo.', {root: true});
            throw error
        }
    },
    
    async changePassword({ commit, dispatch }, data) {
        try {
            const response = await userService.changePassword(data)

        } catch (error) {
            dispatch('alert/error', 'Něco se pokazilo.', {root: true});
            throw error
        }
    },
    async registration({ commit, dispatch }, data) {
        try {
            const response = await userService.registration(data)
            dispatch('alert/success', 'Registrace proběhla úspěšně.', {root: true})
            // router.push('/login-form')
            return true
        } catch (error) {
            dispatch('alert/error', error.response.data.message, {root: true});
            return false
        }
    },
    logout({ commit, dispatch }, data) {
        userService.logout()
            .then(
                response => {
                    commit('logout', response)
                },
                error => {
                    commit('logout', error.response)
                    // TODO
                    // if (error.response.status === 401) {
                    //     commit('logout', error.response)
                    // } else {
                    //     dispatch('alert/error', error, {root: true})
                    // }
                }
            )
    }
}

const mutations = {
    loadProfileRequest (state) {
        state.profile = {
            loading: true,
            status: null
        }
    },
    loadProfileSuccess (state, data) {
        state.profile = {
            data: data,
            status: 200
        }
    },
    loadConfigSuccess (state, data) {
        state.colors = data
    },
    loadProfileFailure (state, error) {
        const status = error.response.status
        state.profile = {
            error: error,
            status: status
        }
        if (status === 401) {
            router.push('/login-form')
        }
    },
    logout (state, response) {
        state.profile = {}
        router.push('/login-form')
    }
}

export const user = {
    namespaced: true,
    state,
    actions,
    mutations
}
