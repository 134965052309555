<template>
  <div class="pt-1 bg-white shadow header">
    <div class="container flex justify-between mx-auto">
      <div class="flex-1 mt-3">
        <router-link to="/dashboard">
          <img class="hidden h-6 md:block lg:block" src="./../assets/logo-signalizator.svg">
          <img class="block h-6 md:hidden lg:hidden" src="./../assets/logo-bonega-square.svg">

        </router-link>
      </div>    
      <div v-if="isLoggedIn" class="hidden w-auto px-4 py-2 text-center md:hidden lg:flex gap-4 place-items-center">
          <span class="mr-2 text-gray-500 text-sm">Kredit:</span>
          <div>
            <strong class="text-green-500">
              <svg class="inline mr-1" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" viewBox="0 0 20 20" width="20" height="20"><g transform="matrix(0.8333333333333334,0,0,0.8333333333333334,0,0)"><path d="M22,7.734a6.042,6.042,0,0,0-6.849-1.186,5.938,5.938,0,0,0-2.292,2.029L9.088,13.991a3.482,3.482,0,0,1-1.313,1.2,3.533,3.533,0,0,1-4.01-.7,3.6,3.6,0,0,1,0-4.994,3.535,3.535,0,0,1,4.994,0,1.25,1.25,0,1,0,1.768-1.768A6.039,6.039,0,0,0,2,7.734a6.1,6.1,0,0,0,0,8.53A6.043,6.043,0,0,0,8.847,17.45a5.938,5.938,0,0,0,2.292-2.029l3.773-5.414a3.482,3.482,0,0,1,1.313-1.2,3.537,3.537,0,0,1,4.01.7,3.6,3.6,0,0,1,0,4.994,3.535,3.535,0,0,1-4.994,0,1.25,1.25,0,1,0-1.768,1.768,6.039,6.039,0,0,0,8.529,0,6.1,6.1,0,0,0,0-8.53Z" fill="currentColor" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"></path></g></svg>              
            </strong>
            <span class="text-gray-500 text-sm">emailů</span>
          </div>
          <div>
            <strong :class="{'text-green-500': user.profile.data.smsCredit > 0, 'text-red-500': user.profile.data.smsCredit <= 0}">{{user.profile.data.smsCredit}}</strong> <span class="mr-2 text-gray-500 text-sm">SMS</span>
          </div>
          <div>
            <strong :class="{'text-green-500': user.profile.data.smsCredit > 0, 'text-red-500': user.profile.data.callCredit <= 0}">{{user.profile.data.callCredit}}</strong> <span class="mr-2 text-gray-500 text-sm">telefonátů</span>
          </div>
          <router-link to="/buy">
            <button class="ml-2 btn btn-yellow">Přikoupit</button>
          </router-link>
          <router-link to="/history">
            <button class="ml-1 btn btn-default">Historie</button>
          </router-link>
      </div>
      <div v-if="isLoggedIn" class="flex-1 pt-1 text-right">
        <Dropdown />
      </div> 
<!--      <div v-if="!isLoggedIn" class="flex-1 pt-1 text-right">-->
<!--        <router-link to="/login-form">-->
<!--          <button class="btn btn-primary">Vstup do aplikace</button>-->
<!--        </router-link>-->
<!--      </div>                -->
    </div>
  </div>
</template>

<script>
import Dropdown from './Dropdown.vue'
import { mapState } from 'vuex'

export default {
  name: 'Header',
  computed: {
      ...mapState({
          user: state => state.user
      }),
      isLoggedIn () {
        return this.user.profile.status === 200
      }
  },
  components: {
    Dropdown
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  min-height: 60px;
}
</style>