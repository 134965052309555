import axios from 'axios'
import qs from 'qs'

const AXIOS = axios.create({
    baseURL: `/`
})

function convertToFormData(jsonData) {
    let formData = new FormData()
    for (let k in jsonData) {
        formData.append(k, jsonData[k])
    }
    return formData;
}

const userService = {
    profile: () => AXIOS.get('/api/v1/user/me'),
    config: () => AXIOS.get('/api/v1/signalizer/colors'),
    feed: () => AXIOS.get('/api/v1/signalizer/feed'),
    history: () => AXIOS.get('/api/v1/signalizer/sms/history'),
    login: (data) => AXIOS.post('/login', qs.stringify(data), {headers: {'content-type': 'application/x-www-form-urlencoded;charset=utf-8'}}),
    registration: (data) => AXIOS.post('/api/v1/user/registration', data),
    reset: (data) => AXIOS.post('/api/v1/user/resetPassword', data),
    changePassword: (data) => AXIOS.post('/api/v1/user/changePassword', data),
    logout: () => AXIOS.get('/logout')
}

const signalizerService = {
    check: (serialNumber) => AXIOS.get('api/v1/signalizer/check/' + encodeURIComponent(serialNumber)),
    load: () => AXIOS.get('api/v1/signalizer/'),
    create: (data) => AXIOS.post('/api/v1/signalizer/', data),
    edit: (id, data) => AXIOS.put(`/api/v1/signalizer/${id}`, data),
    delete: (id) => AXIOS.delete(`/api/v1/signalizer/${id}`),
    history: (signalizerId) => AXIOS.get(`api/v1/signalizer/${signalizerId}/history`),
    temperatures: (signalizerId) => AXIOS.get(`api/v1/signalizer/${signalizerId}/temperatures`),
    uploadFile: (data) => AXIOS.post(`api/v1/signalizer/upload-file`, convertToFormData(data), {headers: {
            'Content-Type': 'multipart/form-data'
        }}),
}

const inputService = {
    load: (signalizerId, position) => AXIOS.get(`api/v1/signalizer/${signalizerId}/input/${position}`),
    edit: (signalizerId, position, data) => AXIOS.put(`api/v1/signalizer/${signalizerId}/input/${position}`, data),
    history: (signalizerId, position) => AXIOS.get(`api/v1/signalizer/${signalizerId}/input/${position}/history`),
}

const inputActionService = {
    load: (signalizerId, position, action) => AXIOS.get(`api/v1/signalizer/${signalizerId}/input/${position}/action/${action}`),
    create: (signalizerId, position, data) => AXIOS.post(`api/v1/signalizer/${signalizerId}/input/${position}/action`, data),
    edit: (signalizerId, position, action, data) => AXIOS.put(`api/v1/signalizer/${signalizerId}/input/${position}/action/${action}`, data),
    patch: (signalizerId, position, data) => AXIOS.put(`api/v1/signalizer/${signalizerId}/input/${position}/settings`, data),
    delete: (signalizerId, position, action, data) => AXIOS.delete(`api/v1/signalizer/${signalizerId}/input/${position}/action/${action}`)
}

const signalizerActionService = {
    load: (signalizerId, action) => AXIOS.get(`api/v1/signalizer/${signalizerId}/action/${action}`),
    create: (signalizerId, data) => AXIOS.post(`api/v1/signalizer/${signalizerId}/action`, data),
    edit: (signalizerId, action, data) => AXIOS.put(`api/v1/signalizer/${signalizerId}/action/${action}`, data),
    delete: (signalizerId, action, data) => AXIOS.delete(`api/v1/signalizer/${signalizerId}/action/${action}`),
}

export {
    userService,
    signalizerService,
    inputService,
    inputActionService,
    signalizerActionService
}