import { mapState } from "vuex";

export default {
    methods: {
        validEmail (email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test()
        },
        getColorState(status) {
            switch (status) {
                case 'CONNECTED':
                case 'On':
                case 'OK':
                    return 'green'
                case 'Off':
                    return 'red'
                case 'DISCONNECTED':
                    return 'red'
                case 'OVERVOLTAGE':
                    return 'yellow'
                case 'UNDERVOLTAGE':
                    return 'blue'
                case 'OVERHEAT':
                    return 'yellow'
                case 'UNDERHEAT':
                    return 'blue'
                case 'PROBE_DISCONNECTED':
                    return 'gray'
                case 'PROBE_ERR_2':
                    return 'gray'
                default:
                    return 'gray'
            }
        },
        getTextStatus(status, type) {
            switch (status) {
                case 'CONNECTED':
                    return 'Zapnuto'
                // case 'OK':
                //     return 'Zapnuto'
                case 'OK':
                    return type === 'input' ? 'Napětí OK' : 'Teplota OK'
                case 'On':
                    return 'Zapnuto'
                case 'Off':
                    return 'Vypnuto'
                case 'DISCONNECTED':
                    return 'Vypnuto'
                case 'OVERVOLTAGE':
                    return 'Přepětí'
                case 'UNDERVOLTAGE':
                    return 'Podpětí'
                case 'UNDERHEAT':
                    return 'Nízká teplota'
                case 'OVERHEAT':
                    return 'Vysoká teplota'
                case 'PROBE_DISCONNECTED':
                    return 'Teplotní sonda odpojena'
                case 'PROBE_ERR_2':
                    return 'Zkrat na teplotní sondě'
                default:
                    return 'Undefined'
            }
        },
        getInputText(input, index) {
            let status = input.status
            let id = input.id
            if (index === 1) {
                status = status === 'Off' ? 'Off' : 'On'
            } else if (index === 2) {
                status = status === 'On' ? 'OK' : status
                if (status === 'Off') {
                    return 'Napětí neměřeno'
                }
            }
            return this.colors && this.colors.inputs && this.colors.inputs[id] && this.colors.inputs[id][status] ?
                this.colors.inputs[id][status].name : this.getTextStatus(status, 'input')
        },
        getColorPropName(stat) {
            switch (stat) {
                case "On":
                    return "onColor";
                case "Off":
                    return "offColor";
                case "UNDERVOLTAGE":
                    return "underVoltageColor";
                case "OVERVOLTAGE":
                    return "overVoltageColor";
                case "OK":
                    return "okColor";
            }
        },
        getInputColor(input, i = false, index) {
            let status = input.status
            const colorPropertyName = this.getColorPropName(status);
            if (index == 1 && input.hasOwnProperty(colorPropertyName) && input[colorPropertyName] != null) {
                return input[colorPropertyName].toLowerCase()
            }
            let id = i ? input.input : input.id
            if (index === 1) {
                status = status === 'Off' ? 'Off' : 'On'
            } else if (index === 2) {
                status = status === 'On' ? 'OK' : status
                if (status === 'Off') {
                    return 'gray'
                }
            }
            return this.colors.inputs && this.colors.inputs[id] && this.colors.inputs[id][status] ?
                this.colors.inputs[id][status].color.toLowerCase() : this.getColorState(status)
        },
        getSignalizatorText (sinalizator, index) {
            let status = sinalizator.status
            let id = sinalizator.id
            if (index === 1) {
                status = status === 'DISCONNECTED' ? 'DISCONNECTED' : 'CONNECTED'
            } else if (index === 2) {
                status = status === 'CONNECTED' ? 'OK' : status
                if (status === 'DISCONNECTED') {
                    return 'Teplota neměřena'
                }
            }
            return this.colors && this.colors.signalizers && this.colors.signalizers[id] && this.colors.signalizers[id][status] ?
                this.colors.signalizers[id][status].name : this.getTextStatus(status, 'signalizer')
        },
        getSignalizatorColor(sinalizator, index) {
            let status = sinalizator.status
            let id = sinalizator.id
            if (index === 1) {
                status = status === 'DISCONNECTED' ? 'DISCONNECTED' : 'CONNECTED'
            } else if (index === 2) {
                status = status === 'CONNECTED' ? 'OK' : status
                if (status === 'DISCONNECTED') {
                    return 'gray'
                }
            }
            return this.colors.signalizers && this.colors.signalizers[id] && this.colors.signalizers[id][status] ?
                this.colors.signalizers[id][status].color.toLowerCase() : this.getColorState(status)
        },
    },
    computed: {
        ...mapState({
            colors: state => state.user.colors
        })
    },
    created () {

    }
}
