<template>
    <div>
        <div>
            <h3 class="text-2xl text-center leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9 mt-4">
                Oveření funkčnosti signalizátoru </h3>

            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="mx-auto max-w-3xl">
                    <div>
                        <div class="space-y-12">
                            <div class="border-b border-gray-900/10 pb-12">
                                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div class="sm:col-span-4">
                                        <label for="serial_number"
                                            class="block text-sm font-medium leading-6 text-gray-900">Seriové
                                            číslo</label>
                                        <div class="mt-2">
                                            <input id="serial_nubmer" name="serial_number" type="text"
                                                v-model="serialNumber"
                                                class="px-2.5 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="mt-6 flex items-center justify-end gap-x-6">
                            <button type="button" @click="qrscan = true"
                                class="text-sm font-semibold leading-6 text-gray-900">Načíst z QR
                                kódu</button>
                            <button @click="checkSignalizator" type="submit"
                                class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Zkontrolovat</button>
                        </div>
                    </div>
                </div>
                <div v-if="qrscan" class="p-4 m-3 w-4/5">
                    <qrcode-stream  :track="paintBoundingBox" @detect="onDetect"></qrcode-stream>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions, mapState } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
    name: 'CheckSignalizator',
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    },
    data() {
        return {
            serialNumber: '',
            qrscan: false
        }
    },
    methods: {
        ...mapActions({
            check: 'signalizer/check',
            error: 'alert/error'
        }),
        checkSignalizator() {
            this.check({ serialNumber: this.serialNumber })
            return false;
        },
        onDetect(detectedCodes) {
            console.log(detectedCodes)
            const thiz = this;
            detectedCodes.then(result => {
                thiz.serialNumber = result.content
                thiz.qrscan = false
                thiz.checkSignalizator()
            })
        },
        paintBoundingBox(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const {
                    boundingBox: { x, y, width, height }
                } = detectedCode

                ctx.lineWidth = 2
                ctx.strokeStyle = '#007bff'
                ctx.strokeRect(x, y, width, height)
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
