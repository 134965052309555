<template>
  <div>
    <h1 class="mt-8 mb-6 text-center">Vstup do aplikace</h1>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-xl mx-auto">
        <div class="mb-4">
          <label class="block text-grey-darker text-sm font-bold mb-2" for="username">
            Emailová adresa
          </label>
          <input class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" v-model="username" type="text" placeholder="jmeno@domena.cz">
        </div>
        <div class="mb-6">
          <label class="block text-grey-darker text-sm font-bold mb-2" for="password">
            Heslo
          </label>
          <input class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" v-model="password" type="password" placeholder="******************">

        </div>
        <div class="flex items-center justify-between">
          <button @click="loginProcess" class="btn btn-primary" type="button">
            Přihlásit
          </button>
          <!-- <a class="inline-block align-baseline text-sm text-blue-500 hover:text-blue-700" href="#">
            Zapomněli jste heslo?
          </a> -->
        </div>
      </div>
      <div class="text-center mt-10">
        <span>Ještě nemáte účet? </span>
          <router-link to="/registration" class="link">
            Registrujte se
          </router-link>
      </div>
      <div class="text-center mt-1">
        <span>Zapomněli jste heslo? </span>
          <router-link to="/reset-password" class="link">
            Reset hesla
          </router-link>
      </div>
<!--    <router-link to="/dashboard">-->
<!--      <button class="btn btn-primary">Přihlásit</button>-->
<!--    </router-link>-->
  </div>
</template>

<script>

  import {mapActions, mapState} from 'vuex'

  export default {
    name: 'Login',
    data: () => {
      return {
        username: '',
        password: ''
      }
    },
    methods: {
      ...mapActions('user', ['login']),
      loginProcess () {
        this.login({
          'username': this.username,
          'password': this.password
        }).then(() => {
          this.$router.push('/dashboard')
        })
      }
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
