<template>
    <div>
      <h1 class="mt-8 mb-6 text-center">Reset hesla</h1>
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-xl mx-auto">
            <div class="mb-4">
                <span v-if="showMessage" class="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">Email s instrukcemi byl odeslan.</span>
            </div>
          <div class="mb-4">
            <label class="block text-grey-darker text-sm font-bold mb-2" for="username">
              Emailová adresa
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" v-model="username" type="text" placeholder="jmeno@domena.cz">
          </div>
          <div class="flex items-center justify-between">
            <button @click="resetProcess" class="btn btn-primary" type="button">
              Reset hesla
            </button>
          </div>
        </div>
    </div>
  </template>
  
  <script>

  import { mapActions, mapState } from 'vuex'
  import ButtonBack from './ButtonBack.vue'



  export default {
    name: 'ResetPassword',
    components: {
      ButtonBack
    },
    data: () => {
      return {
        username: '',
        showMessage: false,
      }
    },
    methods: {
      ...mapActions('user', ['reset']),
      resetProcess () {
        this.reset({
          'username': this.username
        }).then(() => {
          this.showMessage = true;
        })
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>
  