<template>
  <div>
    <div class="mb-6">
    <ButtonBack />
    </div>
    <div class="pb-2 mb-3 mb-10 border-b">
        <button  v-if="!renamingInput" class="float-right ml-1 btn btn-default" @click="rename">Přejmenovat</button>
        <span v-if="!renamingInput" class="text-2xl text-gray-700 font-headings">{{signalizer.name}}</span>
        <span v-if="renamingInput" class="inline-block mt-2">
          <input type="text" class="w-64 input" v-model="name">
          <span class="block mt-1 md:mt-0 lg:mt-0 md:inline lg:inline">
            <button class="ml-1 btn btn-primary" @click="saveEdit">Uložit</button>
            <button class="ml-1 btn btn-default" @click="closeEdit">Storno</button>
          </span>
        </span>        
    </div>    
    <div class="flex flex-wrap md:flex-nowrap justify-between">
      <div class="mb-5 mr-32 flex-init">
        <div class="flex flex-row justify-between gap-1 mb-2">
            <InputStateLabel :currentStateColor="currentStateColor(1)" :currentStateLabel="currentStateLabel(1)" class="text-sm"/>
            <InputStateLabel :currentStateColor="currentStateColor(2)" :currentStateLabel="currentStateLabel(2)" class="text-sm"/>
        </div>
        <div v-if="this.signalizer.histories.length > 0">
                <span class="text-gray-300 ml-2 text-sm"><strong>|</strong></span>
                <span class="text-gray-300 ml-7 text-sm"><strong>|</strong></span>          
        </div>
        <div v-for="h in this.data" v-bind:key="h.id">
          <div class="flex flex-row gap-6 justify-between items-start">
            <div>
              <span :aria-label="h.text1" data-microtip-position="top" role="tooltip"  class="mr-2" :class="{ 'text-green-500': h.color1=='green', 'text-red-500': h.color1=='red', 'text-blue-500': h.color1=='blue', 'text-yellow-500': h.color1=='yellow', 'text-purple-500': h.color1=='purple', 'text-gray-400': h.color1=='gray'}">&#11044;</span>
              <span :aria-label="h.text2" data-microtip-position="top" role="tooltip" class="ml-1" :class="{ 'text-green-500': h.color2=='green', 'text-red-500': h.color2=='red', 'text-blue-500': h.color2=='blue', 'text-yellow-500': h.color2=='yellow', 'text-purple-500': h.color2=='purple', 'text-gray-400': h.color2=='gray'}">&#11044;</span>
              <div v-if="h.next">
                <span class="text-gray-300 ml-2 text-sm"><strong>|</strong></span>
                <span class="text-gray-300 ml-7 text-sm"><strong>|</strong></span>                
              </div>            
            </div>             
            <div class="w-28 pt-1">
              <span class="text-sm inline-block text-gray-500 whitespace-nowrap">{{h.createdAt | datecs}}</span>
            </div>
         
          </div>

        </div>

        <div v-if="next" class="pt-4">
          <button class="btn btn-default" @click="showEvents += 20">Načíst další</button>
        </div>  
      </div>
      <div v-if="loaded" class="flex-grow flex-shrink">
        <TriggerSettings heading="Ztráta spojení (Signálu či napájení)" type="DISCONNECTED" default-name="Vypnuto" source="signalizer" default-color="red"/>
        <TriggerSettings heading="Obnovení spojení (Signálu či napájení)" type="CONNECTED" default-name="Zapnuto" source="signalizer" default-color="green"/>
        <TriggerSettings heading="Překročení teploty" type="OVERHEAT" default-name="Vysoká teplota" default-color="purple" default-value="60" prefix="Při teplotě nad" suffix="°C (max. 60°C)" :enable-value="true" source="signalizer" :min-value="-10" :max-value="60"/>
        <TriggerSettings heading="Pokles teploty" type="UNDERHEAT" default-name="Nízká teplota3" default-color="blue" default-value="20" prefix="Při teplotě pod" suffix="(min. -10°C bez sondy, min. -30 °C se sondou)" :enable-value="true" source="signalizer" :min-value="-10" :max-value="60"/>
        <TriggerSettings heading="Teplota OK" type="OK" default-name="Teplota OK" default-color="green" default-value="20" prefix="Při teplotě pod" source="signalizer"/>
        <TriggerSettings heading="Teplotní sonda - odpojena" type="PROBE_DISCONNECTED" default-name="Odpojená teplotní sonda" default-color="gray" source="signalizer"/>
        <TriggerSettings heading="Teplotní sonda - zkrat" type="PROBE_ERR_2" default-name="Zkrat teplotní sondy" default-color="gray" source="signalizer"/>
      </div>
    </div>                
  </div>
</template>

<script>
  import Vue from 'vue'
import InputStateLabel from './InputStateLabel.vue'
import TriggerSettings from './TriggerSettings.vue'
import ButtonBack from './ButtonBack.vue'
import {mapActions, mapState} from 'vuex'
  import mix from './mixin'

export default {
  name: 'SignalizatorDetail',
  mixins: [mix],
  data () {
    return {
      loaded: false,
      renamingInput: false,
      name: '',
      next: false,
      showEvents: 20
    }
  },
  computed: {
    ...mapState({
      'signalizers': state => state.signalizer.signalizers,
      // 'history': state => state.signalizer.history
    }),
    signalizer () {
      for (let s of this.signalizers) {
        if (s.id == this.$route.params.signalizerId) {
          Vue.nextTick( () => {
            this.loaded = true
          })
          return s
        }
      }
    },
    data () {
      const count = this.signalizer.histories.length
      let current = 0
      this.next = this.showEvents < count
      const self = this
      return this.signalizer.histories.map(x => {
        const req = {status: x.status, id: self.signalizer.id}
        x.color1 = self.getSignalizatorColor(req, 1)
        x.color2 = self.getSignalizatorColor(req, 2)
        x.text1 = self.getSignalizatorText(req, 1)
        x.text2 = self.getSignalizatorText(req, 2) +  (['OVERHEAR', 'UNDERHEAT'].indexOf(x.status) > -1 && x.value ? ` (${x.value}°C +/- 1°C)` : '')
        x.next = (current != count - 1)
        current++
        return x
      }).slice(0, this.showEvents)
    },
  },
  methods: {
    ...mapActions({
      'edit': 'signalizer/edit',
      'loadHistory': 'signalizer/history'
    }),
    currentStateLabel (index) {
      return this.getSignalizatorText(this.signalizer, index)
    },
    currentStateColor (index) {
      return this.getSignalizatorColor(this.signalizer, index)
    },
    rename () {
      this.name = this.signalizer.name
      this.renamingInput = true
    },
    closeEdit () {
      this.renamingInput = false
    },
    saveEdit () {
      // todo valid
      this.edit({name: this.name, id: this.$route.params.signalizerId})
      this.closeEdit()
    }
  },
  created() {
    // console.log(this.$route.params)
    // this.loadHistory(this.$route.params.signalizerId)
  },
  components: {
    InputStateLabel,
    TriggerSettings,
    ButtonBack

  }   
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
