<template>
  <div>
    <ButtonBack />
    <h1 class="mt-4">Změnit heslo</h1>
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-xl mx-auto">
            <div class="mb-4">
                <span v-if="showMessage" class="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">Email s instrukcemi byl odeslan.</span>
            </div>
          <div class="mb-4">
            <label class="block text-grey-darker text-sm font-bold mb-2" for="password">
              Nové heslo
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" v-model="password" type="password">
          </div>
          <div class="flex items-center justify-between">
            <button @click="changePasswordProcess" class="btn btn-primary" type="button">
              Změnit heslo
            </button>
          </div>
        </div>
  </div>
</template>

<script>
import ButtonBack from './ButtonBack.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ChangePassword',
  components: {
    ButtonBack
  },
  data: () => {
      return {
        showMessage: false,
        password: '',
        token: '',
      }
    },
    methods: {
      ...mapActions('user', ['changePassword']),
      changePasswordProcess () {
        this.changePassword({
          'password': this.password,
          'token': this.token
        }).then(() => {
          this.showMessage = true;
          this.$router.push('/login-form')
        })
      }
    },
    mounted() {
      this.token = this.$route.params.token
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
