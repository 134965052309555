<template>
  <div>
    <button :class="{'hidden': recording, 'text-red-500': true, 'px-2 mb-0 ml-1 align-middle btn btn-default': true}" ref="record" @click="handleRecord">&#11044</button>
    <button :class="{'hidden': !recording, 'text-black-500': true, 'px-2 mb-0 ml-1 align-middle btn btn-default': true}" ref="stop">&#x23F9;</button>
    <button :disabled="recording" @click="play" :class="{'hidden': !src || isPlaying, 'text-black-500': true, 'px-2 mb-0 ml-1 align-middle btn btn-default': true}">&#x25b6;</button>
    <button @click="stop" :class="{'hidden': !src || !isPlaying, 'text-black-500': true, 'px-2 mb-0 ml-1 align-middle btn btn-default': true}">&#x23F9;</button>
    <audio @ended="stop" :src="src" :class="{'hidden': true}" ref="player" controls></audio>
  </div>
</template>

<script>

export default {
  name: "RecordAudio",
  emits: ['audioReady'],
  props: {
    url: {
      default: null,
      type: String
    }
  },
  data: () => {
    return {
      state: 'empty',
      file: null,
      src: null,
      show: false,
      recording: false,
      isPlaying: false,
      timer: null
    }
  },
  methods: {
    handleRecord(e) {
      const stopButton = this.$refs['stop'];
      const thiz = this

      this.timer = setTimeout(() => {
        stopButton.click()
      }, 20000);

      this.recording = true
      navigator.mediaDevices
          .getUserMedia({audio: true, video: false})
          .then((stream) => {
            const options = {mimeType: 'audio/webm'};
            const recordedChunks = [];
            const mediaRecorder = new MediaRecorder(stream, options);

            mediaRecorder.addEventListener('dataavailable', function(e) {
              if (e.data.size > 0) recordedChunks.push(e.data);
            });

            mediaRecorder.addEventListener('stop', function() {
              const file = new File(recordedChunks, 'record.webm', {mimeType: 'audio/webm'});
              const url = URL.createObjectURL(file);
              thiz.src = url
              thiz.file = file
              thiz.show = true
              thiz.recording = false
              thiz.$emit('audioReady', file)
              if (thiz.timer != null) {
                clearTimeout(thiz.timer)
                thiz.timer = null;
              }
            });

            stopButton.addEventListener('click', function() {
              mediaRecorder.stop();
            });

            mediaRecorder.start();
          });
    },
    play() {
      this.$refs['player'].play()
      this.isPlaying = true
    },
    stop() {
      this.$refs['player'].pause()
      this.isPlaying = false
      this.$refs['player'].load()
    }
  },
  created() {
    if (this.url) {
      this.src = this.url
    }
  }
}
</script>

<style scoped>

</style>