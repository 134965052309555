<template>
  <div>
    <h1 class="mt-8 mb-6 text-center">Registrace</h1>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-xl mx-auto">
        <div class="mb-4">
          <label class="block text-grey-darker text-sm font-bold mb-2" for="username">
            Emailová adresa
          </label>
          <input class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" v-model="username" type="text" placeholder="jmeno@domena.cz">
        </div>
        <div class="mb-6">
          <label class="block text-grey-darker text-sm font-bold mb-2" for="password">
            Heslo
          </label>
          <input class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" v-model="password" type="password" placeholder="******************">

        </div>
        <div class="mb-6">
          <label class="block text-grey-darker text-sm font-bold mb-2" for="password2">
            Heslo znovu
          </label>
          <input class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" v-model="password2" type="password" placeholder="******************">

        </div>
        <div class="flex items-center justify-between">
          <button @click="loginProcess" class="btn btn-primary" type="button">
            Registrovat
          </button>
<!--          <a class="inline-block align-baseline text-sm text-blue-500 hover:text-blue-700" href="#">-->
<!--            Zapomněli jste heslo?-->
<!--          </a>-->
        </div>
      </div>
<!--    <router-link to="/dashboard">-->
<!--      <button class="btn btn-primary">Přihlásit</button>-->
<!--    </router-link>-->
  </div>
</template>

<script>

  import {mapActions, mapState} from 'vuex'

  export default {
    name: 'Registration',
    data: () => {
      return {
        username: '',
        password: '',
        password2: ''
      }
    },
    methods: {
      ...mapActions('user', ['registration', 'login']),
      ...mapActions('alert', ['error']),
      loginProcess () {
        if (this.password.length < 8) {
          this.error('Heslo je příliš krátké. (min. 8 znaků)')
        }
        if (this.password !== this.password2){
          this.error('Hesla se neshodují.')
          return
        }
        this.registration({
          'username': this.username,
          'password': this.password
        }).then(success => {
          if (success) {
            this.login({
              'username': this.username,
              'password': this.password
            })
          }
        })
      }
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
